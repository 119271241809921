import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo
      title="Page Not Found"
    />
    <div className="max-w-7xl mx-auto px-4 mt-6 mb-24">
      <div className="">
        <h1 className="text-gray-300 text-xl md:text-2xl tracking-wider font-semibold">404. That's an error.</h1>
      </div>

      <div className="mt-16">
        <h2 className="text-gray-200 text-5xl lg:text-7xl font-black">Page Not Found</h2>
        <h3 className="mt-10 text-gray-300 text-2xl lg:text-3xl">The requested URL was not found on this server.</h3>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
